export const TERMS_OF_USE_LINK = 'https://www.onerealmortgage.com/terms-of-use';

export const PRIVACY_POLICY_LINK =
  'https://www.onerealmortgage.com/privacy-policy';

export const ELECTRONIC_DISCLOSURE_CONSENT_LINK =
  'https://www.onerealmortgage.com/edisclosure-consent';

export const REAL_SUPPORT_LINK = 'support@therealbrokerage.com';

export const PHONE_NUMBER = '17047559034';
