import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { get, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty, values } from 'lodash';
import RealLogo from '../assets/img/logo.svg';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad.tsx';
import AuthPageImage from '../components/AuthPageImage';
import Button from '../components/Button';
import DefaultLoader from '../components/DefaultLoader.tsx';
import PageLayout from '../components/PageLayout';
import ControlledConsentInput from '../components/inputs/ControlledConsentInput';
import ControlledPhoneNumberInput from '../components/inputs/ControlledPhoneNumberInput';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import {
  PRIVACY_POLICY_LINK,
  REAL_SUPPORT_LINK,
  TERMS_OF_USE_LINK,
} from '../constants/OneRealMortgageConstants';
import { AuthControllerApi, LoginRequest } from '../openapi/keymaker';
import {
  OnboardUserRequestRoleEnum,
  PublicControllerApi,
  SignUpRequestCountryEnum,
  UserControllerApi,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService.ts';
import { fetchAuthUserDetail, signOutUser } from '../slices/AuthSlice';
import { useAppDispatch } from '../slices/store';
import { AnalyticsEventEnum, ISelectOption } from '../types.ts';
import { setAuthCookie } from '../utils/AuthUtils';
import { getCommonsApiErrorMessage } from '../utils/ErrorUtils.ts';
import Logger from '../utils/Logger';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import {
  EMAIL_VALIDATIONS,
  PASSWORD_VALIDATIONS,
  USERNAME_VALIDATIONS,
} from '../utils/Validations';
import ControlledReactSelectInput from '../components/inputs/ControlledReactSelectInput.tsx';
import { capitalizeEnum } from '../utils/EnumHelper.ts';
import { ALPHA_NUMERIC_REGEX_NO_SPACE } from '../utils/RegexUtils.ts';

interface OnboardingSignUpRouteProps {}

interface FormData {
  firstName: string;
  lastName: string;
  username: string;
  country: ISelectOption;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  consentedToTerms: boolean;
  submit: string;
}

const OnboardingSignUpRoute: React.FC<OnboardingSignUpRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { connectionParticipantId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [isTCError, setIsTCError] = useState<string>('');
  const [onboardingEmail, setOnboardingEmail] = useState<string>('');
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm<FormData>({
    mode: 'onBlur',
  });

  const fetchTCUserData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await new PublicControllerApi(
        await getYentaConfiguration(),
      ).getExternalConnectionBasicInfo(connectionParticipantId as string);

      if (!data.expired) {
        setValue('firstName', data.firstName!);
        setValue('lastName', data.lastName!);
        setValue('emailAddress', data.emailAddress!);
        setOnboardingEmail(data.emailAddress!);
      } else {
        setIsTCError('expired');
      }
    } catch (e: any) {
      setIsTCError('invalid');
      Logger.error('Unable to fetch TC onboarding info', e);
      ErrorService.notifyIgnoreHandled('Unable to fetch TC onboarding info', e);
    } finally {
      setLoading(false);
    }
  }, [setValue, connectionParticipantId]);

  useEffect(() => {
    if (connectionParticipantId) {
      fetchTCUserData();
    }
  }, [fetchTCUserData, connectionParticipantId]);

  const onSubmit = async (values: FormData) => {
    try {
      await new PublicControllerApi(await getYentaConfiguration()).userSignup({
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username!,
        country: values.country?.value,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        password: values.password,
      });

      const loginRequest: LoginRequest = {
        usernameOrEmail: values.emailAddress,
        password: values.password,
      };

      const {
        data: { accessToken },
      } = await new AuthControllerApi(
        await getKeymakerConfiguration(),
      ).authenticateUser(loginRequest);

      setAuthCookie(accessToken!);

      const { userData } = await dispatch(fetchAuthUserDetail(undefined, true));

      await new UserControllerApi(await getYentaConfiguration()).addOnboarding(
        userData?.id!,
        {
          role: OnboardUserRequestRoleEnum.TransactionCoordinator,
        },
      );

      const { userData: updatedUserData } = await dispatch(
        fetchAuthUserDetail(),
      );
      navigate(
        `/onboarding/${updatedUserData?.onboardings![0]!
          .sherlockApplicationId}`,
      );
    } catch (e: any) {
      Logger.error('Unable to sign up', e);
      ErrorService.notifyIgnoreHandled('Unable to sign up', e);
      setError('submit', {
        message: getCommonsApiErrorMessage(e),
      });
      // this will sign out the TC user when signup is success and onBoarding fails.
      await dispatch(signOutUser());
    }
  };

  if (loading) {
    return (
      <div className='h-screen'>
        <DefaultLoader />;
      </div>
    );
  }

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 grid md:grid-flow-row md:grid-rows-6 md:justify-between max-w-lg mx-auto overflow-scroll'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='md:row-span-4 md:my-auto mx-5 md:mx-0 overflow-scroll'
        >
          <div className='pb-7 mt-10 md:mt-0'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Create your account
            </p>
            <div className='pt-4'>
              <p>
                Already have an account?
                <Link to='/login'>
                  <span className='text-primary-blue underline pl-1'>
                    Sign in
                  </span>
                </Link>
              </p>
            </div>
          </div>
          {get(errors, 'submit') && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {get(errors, 'submit').message}
              </p>
            </div>
          )}
          {!isEmpty(isTCError) && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {isTCError === 'invalid' && (
                  <span>
                    Sorry, your invitation cannot be found. Please reach out to{' '}
                    <a
                      className='underline'
                      href={`mailto:${REAL_SUPPORT_LINK}`}
                      target='_top'
                      rel='noreferrer'
                    >
                      support@therealbrokerage.com
                    </a>{' '}
                    if you have questions.
                  </span>
                )}
                {isTCError === 'expired' &&
                  'Sorry, your invitation has expired. Please ask your inviting agent to resend the invite.'}
              </p>
            </div>
          )}
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'firstName'>
              name='firstName'
              control={control}
              label='First Name'
              placeholder='First Name'
              startAdornment={
                <FontAwesomeIcon
                  icon={faUser}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              shouldUnregister={false}
              rules={{
                required: 'Please enter first name',
              }}
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'lastName'>
              name='lastName'
              control={control}
              label='Last Name'
              startAdornment={
                <FontAwesomeIcon
                  icon={faUser}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              placeholder='Last Name'
              shouldUnregister={false}
              rules={{
                required: 'Please enter last name',
              }}
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'username'>
              name='username'
              control={control}
              placeholder='Username'
              label='Username'
              startAdornment={
                <FontAwesomeIcon
                  icon={faUser}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              shouldUnregister={false}
              rules={{
                required: 'Please enter username',
                ...USERNAME_VALIDATIONS,
                validate: async (value) => {
                  if (!ALPHA_NUMERIC_REGEX_NO_SPACE.test(value!)) {
                    return 'Username can only have letters and numbers';
                  }
                  const { data: username } = await new AuthControllerApi(
                    await getKeymakerConfiguration(),
                  ).checkUsernameAvailability(value!);

                  return !username.available
                    ? 'Username is already taken'
                    : undefined;
                },
              }}
            />
          </div>
          <div className='pb-5'>
            <ControlledReactSelectInput
              name='country'
              label='Country'
              placeholder='Country'
              control={control}
              options={values(SignUpRequestCountryEnum).map((c) => ({
                label: capitalizeEnum(c),
                value: c,
              }))}
              rules={{ required: 'Please select country' }}
              closeMenuOnSelect
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'emailAddress'>
              name='emailAddress'
              control={control}
              placeholder='Email address'
              label='Email address'
              startAdornment={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              shouldUnregister={false}
              rules={{
                required: 'Please enter email address',
                ...EMAIL_VALIDATIONS,
                validate: async (value) => {
                  const { data: email } = await new AuthControllerApi(
                    await getKeymakerConfiguration(),
                  ).checkEmailAvailability(value);

                  return !email.available
                    ? 'Email is already taken'
                    : undefined;
                },
              }}
              disabled={!isEmpty(onboardingEmail)}
            />
          </div>
          <div className='pb-5'>
            <ControlledPhoneNumberInput<FormData, 'phoneNumber'>
              name='phoneNumber'
              control={control}
              placeholder='Phone Number'
              label='Phone Number'
              rules={{
                required: 'Please enter phone number',
              }}
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'password'>
              placeholder='Password'
              name='password'
              label='Password'
              control={control}
              shouldUnregister={false}
              rules={PASSWORD_VALIDATIONS}
              type={hidePassword ? 'password' : 'text'}
              defaultValue=''
              startAdornment={
                <FontAwesomeIcon
                  icon={faLock}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  onClick={() => setHidePassword(!hidePassword)}
                  icon={hidePassword ? faEyeSlash : faEye}
                  fontSize={20}
                  className='text-primary-gray'
                  data-testid='password'
                />
              }
              isPassword
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'confirmPassword'>
              placeholder='Confirm Password'
              name='confirmPassword'
              label='Confirm Password'
              control={control}
              shouldUnregister={false}
              startAdornment={
                <FontAwesomeIcon
                  icon={faLock}
                  fontSize={16}
                  className='text-primary-skyblue'
                />
              }
              rules={{
                required: 'Please re-enter your password',
                validate: (value) =>
                  getValues().password !== value
                    ? 'Passwords do not match'
                    : undefined,
              }}
              type={hideConfirmPassword ? 'password' : 'text'}
              defaultValue=''
              endAdornment={
                <FontAwesomeIcon
                  onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  icon={hideConfirmPassword ? faEyeSlash : faEye}
                  fontSize={20}
                  className='text-primary-gray'
                  data-testid='confirmPassword'
                />
              }
              isPassword
            />
          </div>
          <div className='pb-5'>
            <ControlledConsentInput<FormData, 'consentedToTerms'>
              name='consentedToTerms'
              control={control}
              data-testid='consentedToTerms'
              labelComponent={
                <div data-testid='consentedToTerms'>
                  <p className='font-inter-medium text-xs text-primary-gray'>
                    You agree to our{' '}
                    <a
                      className='text-primary-blue'
                      href={TERMS_OF_USE_LINK}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms of Use
                    </a>{' '}
                    &{' '}
                    <a
                      className='text-primary-blue'
                      href={PRIVACY_POLICY_LINK}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              }
              rules={{
                required: 'Please provide your consent to continue',
              }}
            />
          </div>
          <p className='pb-5 font-inter-light text-xs text-primary-gray'>
            By providing my phone number above and clicking the “Continue”
            button below, I agree to the Privacy Policy and Terms of Use and I
            also consent by electronic signature to receive calls and text
            messages from One Real Mortgage made by any means or technology,
            including a prerecorded message, artificial voice, automatic
            telephone dialing system or other automated system for the selection
            and dialing of telephone numbers, for informational, transactional,
            marketing or any other purposes at my phone number. I understand
            that my consent to such calls and text messages is not required to
            purchase products from or use the services of One Real Mortgage.
          </p>
          <div className='flex w-full'>
            <Button
              label='Continue'
              type='submit'
              fullWidth={isMobile}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  fontSize={16}
                  className='text-white'
                />
              }
              gradientVariant='mintyfresh'
              isSubmitting={isSubmitting}
              isDisabled={!isEmpty(isTCError)}
              onPress={() => {
                if (get(errors, 'submit')) {
                  clearErrors('submit');
                }
              }}
            />
          </div>
        </form>
      </div>
      <AuthPageImage />
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.SIGNUP_SCREEN_VIEWED}
      />
    </PageLayout>
  );
};

export default OnboardingSignUpRoute;
